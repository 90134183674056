<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("catalogImport.catalogImport") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start custom-input whitebg-input m-2">
            <div class="row mb-3 align-items-center">
              <div class="col-6 mb-2">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.catalogName")
                }}</label>
                <input type="text" v-model="name" class="form-control" />
              </div>
              <div class="col-3 mb-2">
                <label class="form-label bold-12 text-gray">{{
                  $t("finance.language")
                }}</label>
                <v-select
                  v-model="language"
                  :options="languages"
                  :placeholder="$t(`finance.language`)"
                  label="name"
                  :reduce="(e) => e.code"
                  :clearable="false"
                />
              </div>
              <div class="col-3 mb-2">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.defaultCurrency")
                }}</label>
                <v-select
                  v-model="currency"
                  :options="currencies"
                  :placeholder="$t(`bankAccount.currency`)"
                  label="code"
                  :reduce="(e) => e.code"
                  :clearable="false"
                />
              </div>
              <div class="col-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.accessBy")
                }}</label>
                <select class="form-control" v-model="accessType">
                  <option :value="1">{{ $t("catalogImport.fileUrl") }}</option>
                  <option :value="2">
                    {{ $t("catalogImport.fileUpload") }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label class="form-label bold-12 text-gray">
                  {{ accessType === 1 ? "File URL" : "File" }}
                  <BaseIcon name="xlsx" type="formats" />
                  <BaseIcon name="xml" type="formats" />
                  <BaseIcon name="json" type="formats" />
                  <BaseIcon name="csv" type="formats" />
                </label>
                <input
                  type="text"
                  v-model="url"
                  v-on:change="checkFormat"
                  class="form-control"
                  v-if="accessType === 1"
                />
                <input
                  type="file"
                  ref="fileInput"
                  v-on:change="checkFormatLocal"
                  class="form-control"
                  v-else
                />
              </div>
              <div class="col-3" v-if="accessType === 1">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.authentice")
                }}</label>
                <select class="form-control" v-model="authType">
                  <option :value="null">
                    {{ $t("catalogImport.noAuthentice") }}
                  </option>
                  <option :value="1">
                    {{ $t("catalogImport.basicAuthentice") }}
                  </option>
                  <option :value="2">
                    {{ $t("catalogImport.beaerAuthentice") }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3 align-items-center" v-if="authType == 1">
              <div class="col-6">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.username")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="authUser"
                  v-on:change="checkFormatLocal"
                />
              </div>
              <div class="col-6">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.password")
                }}</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="authPass"
                  v-on:change="checkFormatLocal"
                />
              </div>
            </div>
            <div class="row mb-3 align-items-center" v-else-if="authType == 2">
              <div class="col-12">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.authToken")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="authPass"
                  v-on:change="checkFormatLocal"
                />
              </div>
            </div>
            <div
              class="row mb-3 align-items-center"
              v-if="fileFormat === 'csv' || fileFormat === 'xlsx'"
            >
              <div class="col-9">
                <label class="cstm-check d-flex" for="ct-1">
                  <span class="regular-12 text-black mx-3">
                    {{ $t("catalogImport.the") }}
                    <select v-model="firstRowNumber">
                      <option :value="i" v-for="i in 10" :key="i">
                        {{ columnNumbers[i - 1] }}
                      </option>
                    </select>
                    {{ $t("catalogImport.rowContainsTheColumnsNames") }}
                  </span>
                  <input type="checkbox" v-model="columnNames" id="ct-1" />
                  <span class="checkmark mt-3"></span>
                </label>
              </div>
              <div class="col-3" v-if="fileFormat === 'csv'">
                {{ $t("catalogImport.use") }}
                <select v-model="separator">
                  <option value=";">;</option>
                  <option value=",">,</option>
                  <option value="|">|</option>
                </select>
                {{ $t("catalogImport.asSeparator") }}
              </div>
            </div>
            <div class="row" v-for="(column, i) in columns" :key="i">
              <div class="col-6 mb-3" v-if="column.length > 0">
                <div>
                  <b v-if="columnNames">{{
                    examples[i][firstRowNumber - 1]
                  }}</b>
                  <b v-else>{{
                    $t(`catalogImport.column`, {
                      ordinal_suffix_of: ordinal_suffix_of(i),
                    })
                  }}</b>
                  <span class="text-gray-dark regular-14 mx-2">{{
                    $t("catalogImport.exampleData")
                  }}</span>
                </div>
                <div
                  class="text-gray regular-14"
                  v-for="example in examples[i].slice(
                    firstRowNumber,
                    firstRowNumber + 3
                  )"
                  :key="example"
                >
                  {{ example }}
                </div>
              </div>
              <div class="col-6 mb-3" v-if="column.length > 0">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.type")
                }}</label>
                <v-select
                  v-model="types[i]"
                  :options="rowOptions"
                  :placeholder="$t(`catalogImport.selectAType`)"
                  label="name"
                  :clearable="true"
                />
              </div>
            </div>
            <div class="row" v-if="success && remote">
              <div class="col-6">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.importAutomatically")
                }}</label>
                <select class="form-control" v-model="importDay">
                  <option value="">{{ $t("catalogImport.never") }}</option>
                  <option value="9">{{ $t("catalogImport.everyDay") }}</option>
                  <option value="1">
                    {{ $t("catalogImport.everyMonday") }}
                  </option>
                  <option value="2">
                    {{ $t("catalogImport.everyTuesday") }}
                  </option>
                  <option value="3">
                    {{ $t("catalogImport.everyWednesday") }}
                  </option>
                  <option value="4">
                    {{ $t("catalogImport.everyThursday") }}
                  </option>
                  <option value="5">
                    {{ $t("catalogImport.everyFriday") }}
                  </option>
                  <option value="6">
                    {{ $t("catalogImport.everySaturday") }}
                  </option>
                  <option value="7">
                    {{ $t("catalogImport.everySunday") }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <label class="form-label bold-12 text-gray">{{
                  $t("catalogImport.importTime")
                }}</label>
                <select class="form-control" v-model="importTime">
                  <option v-for="i in 24" :key="i" :value="i">
                    {{ $t(`catalogImport.00`, { expr: i > 9 ? i : "0" + i }) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer custom-input">
          <button
            class="btn btn-primary"
            v-on:click="save"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("catalogImport.import") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../icons/BaseIcon";
import http from "../../modules/http";
const bootstrap = require("bootstrap");
import vSelect from "vue-select";
import { useStore } from "vuex";

import { read, utils } from "xlsx";
import { set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
set_cptable(cptable);

export default {
  name: "CatalogImport",
  props: {
    modelValue: Object,
    show: Boolean,
    partner: Number,
    id: Number,
  },
  computed: {
    currencies() {
      const store = useStore();
      return store.state.currencies;
    },
    languages() {
      const store = useStore();
      return store.state.languages;
    },
  },
  emits: ["update:modelValue", "update:show", "add"],
  data() {
    return {
      loadAddBtn: false,
      success: false,
      remote: false,
      url: "",
      separator: ";",
      accessType: 1,
      fileFormat: null,
      currency: "EUR",
      language: "en",
      importTime: 5,
      importDay: "",
      columnNumbers: [
        this.$t("serialNumbers.first"),
        this.$t("serialNumbers.second"),
        this.$t("serialNumbers.third"),
        this.$t("serialNumbers.fourth"),
        this.$t("serialNumbers.fifth"),
        this.$t("serialNumbers.sixth"),
        this.$t("serialNumbers.seventh"),
        this.$t("serialNumbers.eighth"),
        this.$t("serialNumbers.ninth"),
        this.$t("serialNumbers.tenth"),
      ],
      columns: [],
      examples: {},
      authType: null,
      authUser: "",
      authPass: "",
      firstRowNumber: 1,
      columnNames: false,
      types: {},
      name: "",
      rowOptions: [
        {
          id: 1,
          name: "Name",
          is_fix: true,
        },
        {
          id: 2,
          name: "Price",
          is_fix: true,
        },
        {
          id: 3,
          name: "Currency",
          is_fix: true,
        },
        {
          id: 4,
          name: "Price and currency",
          is_fix: true,
        },
        {
          id: 5,
          name: "SKU",
          is_fix: true,
        },
        {
          id: 6,
          name: "MPN",
          is_fix: true,
        },
        {
          id: 7,
          name: "UPC",
          is_fix: true,
        },
        {
          id: 8,
          name: "EAN",
          is_fix: true,
        },
        {
          id: 9,
          name: "Condition",
          is_fix: true,
        },
        {
          id: 10,
          name: "Image",
          is_fix: true,
        },
        {
          id: 11,
          name: "Description",
          is_fix: true,
        },
      ],
      myModal: null,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
      this.load();
    } else {
      this.myModal.hide();
    }
    const _this = this;
    this.$refs.modal.addEventListener("hidden.bs.modal", function () {
      _this.$emit("update:show", false);
    });
  },
  watch: {
    show() {
      if (this.show) {
        this.myModal.show();
        this.load();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    load() {
      if (this.id !== null) {
        http
          .fetch("/crm/partners/" + this.partner + "/catalogs/" + this.id)
          .then((data) => {
            console.log(data);
            this.name = data.data.name;
            this.language = data.data.language;

            this.url = data.data.url;
            this.authUser = data.data.auth_user;
            this.authPass = data.data.auth_password;

            this.importDay = data.data.import_frequency;
            this.importTime = data.data.import_time;

            this.columns = [];
            for (const col of data.data.partnerCatalogColumns) {
              if (col.col_name) {
                this.columnNames = true;
              }
              this.types[col.col_number ? col.col_number : col.col_name] = {
                name: col.col_name,
                id: col.type ? col.type : col.characteristics_type_id,
                is_fix: col.type ? 0 : 1,
              };
            }
            this.examples = {};
          });
      }
      http
        .fetch("/products/products/characteristics?language=en")
        .then((data) => {
          for (const row of data) {
            this.rowOptions.push(row);
          }
        });
    },
    ordinal_suffix_of(i) {
      if (i < 10) {
        return this.columnNumbers[i];
      }
      i++;
      if (this.$i18n.locale !== "en") {
        return i + ".";
      }
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    },

    save() {
      this.loadAddBtn = true;
      let data = {
        columns: {},
        starting_row:
          this.fileFormat === "csv" ||
          (this.fileFormat === "xlsx" && this.columnNames)
            ? this.firstRowNumber
            : null,
        separator:
          this.fileFormat === "csv" || this.fileFormat === "xlsx"
            ? this.separator
            : null,
        name: this.name,
        language: this.language,
        default_currency: this.currency,
        partner_id: this.partner,
      };
      for (const key in this.types) {
        console.log(this.types[key]);
        data.columns[
          this.fileFormat === "json" || this.fileFormat === "xml"
            ? this.types[key].name
            : key
        ] = {
          id: this.types[key].id,
          is_characteristics: this.types[key].is_fix ? 0 : 1,
        };
      }
      if (this.accessType === 1) {
        data.url = this.url;
        data.auth_type = this.authType;
        if (this.authType > 0) {
          data.auth_user = this.authUser;
          data.auth_password = this.authPass;
        }

        data.import_frequency = this.importDay;
        data.import_time = this.importTime;
      } else {
        data["file"] = this.$refs.fileInput.files[0];
      }
      if (this.id !== null) {
        return;
      }
      http
        .fetch("/crm/partners/" + this.partner + "/catalogs", data, true)
        .then((data) => {
          console.log(data);
          this.myModal.hide();
          this.$emit("add", data.data);
          this.$emit("update:show", false);
          this.columns = [];
          this.examples = {};
          this.loadAddBtn = false;
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },

    checkFormat() {
      let data = { url: this.url };
      data.auth_type = this.authType;
      if (this.authType > 0) {
        data.auth_user = this.authUser;
        data.auth_password = this.authPass;
      }

      http
        .fetch("/crm/partners/catalogs/check", data)
        .then((data) => {
          this.success = data.success;
          this.columns = data.columns;
          this.examples = {};
          for (const key in this.columns) {
            this.examples[key] = [this.columns[key]];
          }
          this.fileFormat = data.format;
          this.columnNames = true;
          this.remote = true;
        })
        .catch(() => {
          this.success = false;
        });
    },

    checkFormatLocal() {
      const files = this.$refs.fileInput.files;
      if (!files.length) {
        alert("Please select a file!");
      }
      this.remote = false;
      const file = files[0];
      console.log(file.type);

      if (
        file.type === "text/csv" ||
        file.name.indexOf(".csv") > file.name.length - 6
      ) {
        this.localCsv(file);
        this.fileFormat = "csv";
        this.columnNames = true;
      } else if (
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/wps-office.xlsx" ||
        file.name.indexOf(".xlsx") > file.name.length - 6 ||
        file.name.indexOf(".xls") > file.name.length - 6
      ) {
        this.xlsxToArray(file);
        this.fileFormat = "xlsx";
        this.columnNames = true;
      } else if (
        file.type === "text/json" ||
        file.name.indexOf(".json") > file.name.length - 6
      ) {
        this.jsonToArray(file);
        this.fileFormat = "json";
        this.columnNames = true;
      } else if (
        file.type === "text/xml" ||
        file.name.indexOf(".xml") > file.name.length - 6
      ) {
        this.xmlToArray(file);
        this.fileFormat = "xml";
        this.columnNames = true;
      }
    },

    localCsv(file, end = 40) {
      this.readBlob(file, 0, end).then((text) => {
        if (text.split("\n").length < 12 && file.size > end) {
          this.localCsv(file, end + 40);
          return;
        }

        const lines = text.split("\n");

        let firstLine = true;
        this.examples = {};
        for (const line of lines) {
          if (firstLine) {
            this.columns = this.CSVtoArray(line);
            this.success = true;
            firstLine = false;

            for (const key in this.columns) {
              this.examples[key] = [];
            }
          }
          if (line.length > 2) {
            let data = this.CSVtoArray(line);
            for (const key in data) {
              this.examples[key].push(data[key]);
            }
          }
        }
      });
    },

    CSVtoArray(text) {
      const re_valid =
        /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:(,|;)\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      const re_value =
        /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,;'"\s\\]*(?:\s+[^,;'"\s\\]+)*))\s*(?:,|;|$)/g;
      // Return NULL if input string is not well formed CSV string.
      if (!re_valid.test(text)) return null;
      let a = []; // Initialize array to receive values.
      text.replace(
        re_value, // "Walk" the string using replace with callback.
        function (m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
          else if (m3 !== undefined) a.push(m3);
          return ""; // Return empty string.
        }
      );
      // Handle special case of empty last value.
      if (/,\s*$/.test(text)) a.push("");
      return a;
    },

    xlsxToArray(file) {
      const _this = this;
      let reader = new FileReader();

      reader.onload = function (e) {
        let data = e.target.result;
        let workbook = read(data, {
          type: "binary",
          sheetRows: 12,
        });

        let sheetName = workbook.SheetNames[0];
        let XL_row_object = utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        _this.columns = Object.keys(XL_row_object[0]);
        _this.examples = {};
        for (const key in _this.columns) {
          _this.examples[key] = [];
          _this.examples[key].push(_this.columns[key]);
          for (const key2 in XL_row_object) {
            _this.examples[key].push(XL_row_object[key2][_this.columns[key]]);
          }
        }
        _this.success = true;
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file);
    },

    jsonToArray(file) {
      this.readBlob(file, 0, file.size).then((text) => {
        let data = JSON.parse(text);
        while (Object.keys(data).length < 3 && Object.keys(data).length > 0) {
          data = data[Object.keys(data)[0]];
        }

        this.columns = Object.keys(data[0]);

        this.examples = {};
        for (const key in this.columns) {
          this.examples[key] = [];
          this.examples[key].push(this.columns[key]);
          let i = 0;
          for (const key2 in data) {
            this.examples[key].push(data[key2][this.columns[key]]);
            i++;
            if (i > 12) {
              break;
            }
          }
        }
        this.success = true;
      });
    },

    xmlToObject(xml) {
      var obj = {};

      if (xml.nodeType == 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
          obj["@attributes"] = {};
          for (var j = 0; j < xml.attributes.length; j++) {
            var attribute = xml.attributes.item(j);
            obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType == 3) {
        // text
        obj = xml.nodeValue;
      }

      // do children
      if (xml.hasChildNodes()) {
        for (var i = 0; i < xml.childNodes.length; i++) {
          var item = xml.childNodes.item(i);
          var nodeName = item.nodeName;
          if (typeof obj[nodeName] == "undefined") {
            obj[nodeName] = this.xmlToObject(item);
          } else {
            if (typeof obj[nodeName].push == "undefined") {
              var old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(this.xmlToObject(item));
          }
        }
      }
      return obj;
    },

    xmlToArray(file) {
      this.readBlob(file, 0, file.size).then((text) => {
        let parser = new DOMParser();
        let data = this.xmlToObject(parser.parseFromString(text, "text/xml"));

        if (data["#text"] !== undefined) {
          delete data["#text"];
        }

        while (Object.keys(data).length < 3 && Object.keys(data).length > 0) {
          if (data["#text"] !== undefined) {
            delete data["#text"];
          }
          data = data[Object.keys(data)[0]];
        }

        if (data[0]["#text"] !== undefined) {
          delete data[0]["#text"];
        }

        this.columns = Object.keys(data[0]);

        this.examples = {};
        for (const key in this.columns) {
          this.examples[key] = [];
          this.examples[key].push(this.columns[key]);
          let i = 0;
          for (const key2 in data) {
            this.examples[key].push(data[key2][this.columns[key]]["#text"]);
            i++;
            if (i > 12) {
              break;
            }
          }
        }
        this.success = true;
      });
    },

    readBlob(file, opt_startByte, opt_stopByte) {
      return new Promise((resolve, reject) => {
        let start = parseInt(opt_startByte) || 0;
        let stop = parseInt(opt_stopByte) || file.size - 1;

        let reader = new FileReader();

        reader.onloadend = function (evt) {
          if (evt.target.readyState === FileReader.DONE) {
            resolve(evt.target.result);
          } else if (evt.target.readyState === FileReader.EMPTY) {
            reject();
          }
        };

        var blob = file.slice(start, stop + 1);
        reader.readAsBinaryString(blob);
      });
    },
  },
  components: { BaseIcon, vSelect },
};
</script>
